.header {
  position: relative;
  border-bottom: 1px solid var(--primary-10);
  position: fixed;
  -webkit-backface-visibility: hidden;
  width: 100%;
  background: #fff;
  z-index: 50;

  .nav-wrapper {
    height: var(--nav-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    position: relative;
  }

  .logo-container {
    width: 130px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  .icon-modernist {
    color: var(--primary-85);
    height: 30px;
    width: 130px;
    pointer-events: none;
  }

  .arrow-button {
    top: 2px;
  }

  .nav-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    &.left {
      .nav-link-wrapper {
        &:first-of-type {
          margin-right: 1rem;
        }
      }
    }
  }

  // wrapper used to prevent focus ring from outlining pseudo element
  .nav-link-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .nav-link,
  .nav-icon {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 3px;
      background-color: transparent;
      transition: background-color 0.25s;
      outline: none;
      pointer-events: none;
    }

    &:hover,
    &:focus,
    &.is-open {
      outline: none;
      &:after {
        background-color: var(--accent-100);
      }
    }
  }

  .nav-link {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--primary-85);
    padding: 0 0.5rem;
    position: relative;
    font-size: inherit;
  }

  .nav-icon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--primary-85);
    padding: 1rem;
    position: relative;
  }

  .mobile-only {
    display: none;
  }

  // CSSTransition (react-transition-group) - applied when element added/removed from DOM
  .search-drawer-enter {
    top: calc(0px - var(--nav-height));

    &.search-drawer-enter-active {
      top: 0px;
      transition: top 0.5s;
    }
  }

  .search-drawer-exit {
    top: 0px;

    &.search-drawer-exit-active {
      top: calc(0px - var(--nav-height));
      transition: top 0.5s;
    }
  }

  .mobile-nav-enter {
    transform: translateY(-100%);

    &.mobile-nav-enter-active {
      transform: translateY(0%);
      transition: transform 0.3s;
    }
  }

  .mobile-nav-exit {
    transform: translateY(0%);

    &.mobile-nav-exit-active {
      transform: translateY(-100%);
      transition: transform 0.3s;
    }
  }
}

@media (max-width: 800px) {
  .header {
    .mobile-only {
      display: block;
    }

    .hamburger {
      padding-left: 0;
    }

    .cart-icon {
      padding-right: 0;
    }

    .desktop-only {
      display: none;
    }

    .nav-link,
    .nav-icon {
      &:after {
        display: none;
      }
    }
  }
}
