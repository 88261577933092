.image-loader-wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  .spinner-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--primary-07);
  }

  .spinner {
    max-width: 35%;
    max-height: 35%;
  }
}
