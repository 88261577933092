@font-face {
  font-family: "proxima_nova";
  src: url("proximanova-regular-webfont.woff2") format("woff2"),
    url("proximanova-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima_nova";
  src: url("proximanova-semibold-webfont.woff2") format("woff2"),
    url("proximanova-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima_nova";
  src: url("proximanova-bold-webfont.woff2") format("woff2"),
    url("proximanova-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "proxima_nova_condensedmedium";
  src: url("proximanovacond-medium.woff2") format("woff2"),
    url("proximanovacond-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "domaine_disp_nar";
  src: url("domainedispnar-medium.woff2") format("woff2"),
    url("domainedispnar-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
