.search-drawer {
  background-color: #fff;
  height: var(--nav-height);
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  color: var(--primary-85);
  display: flex;
  align-items: center;
  z-index: 5;

  .close-button {
    border-radius: 3px;
    transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
    width: 42px;
    height: 42px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    color: var(--primary-30);
    border: 1px solid var(--primary-10);

    &:focus,
    &:hover {
      color: var(--primary-40);
      border-color: var(--primary-20);
    }
  }

  .icon-close {
    pointer-events: none;
  }

  .icon-collapse {
    width: 1rem;
    height: 1rem;
  }
}

@media (max-width: 800px) {
  .search-drawer {
    display: none;
  }
}
