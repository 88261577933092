.search-result {
  &:last-of-type {
    .result-link {
      border-radius: 0 0 4px 4px;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--primary-10);
  }

  &.no-results {
    padding: 0.75rem;
  }
}

.result-link {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  transition: background-color 0.3s ease;

  > * {
    pointer-events: none;
  }

  &:hover,
  &:focus {
    background-color: var(--primary-03);
  }
}

.result-img {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  background-color: var(--primary-05);
  border-radius: 2px;
  padding: 0.5rem;
}

.result-details {
  margin-left: 1rem;

  > span {
    display: block;
  }

  .result-price {
    font-weight: var(--font-bold);
    font-size: 1.125rem;
  }
}
