@import "../../sharedStyles/breakpoints.scss";

.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 1.5rem;
  overflow-y: auto;
  text-align: center;

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--overlay);
  }

  .modal-box {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    z-index: 100;
    padding: 2.25rem;
    max-width: 500px;
    margin: 0 auto;
    position: relative;
    display: inline-block;
    text-align: center;
    border-radius: 0.375rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .modal-button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .custom-button:not(:last-of-type) {
      margin-right: 1rem;
    }
  }

  h1 {
    margin-bottom: 0.75rem;
    font-size: 2rem;
  }
}

@media (max-width: $mobile) {
  .modal-wrapper {
    .modal-box {
      padding: 2rem;
    }
  }
}
