.arrow-button {
  --arrow-height: 7px;
  --arrow-thickness: 1px;
  width: 0.85rem;
  margin-left: 0.5rem;
  height: var(--arrow-height);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:before,
  &:after {
    position: absolute;
    content: "";
    width: var(--arrow-thickness);
    height: var(--arrow-height);
    background-color: var(--primary-65);
    transition: all 0.2s ease-in-out;
    border-radius: var(--arrow-thickness);
  }

  &:before {
    left: 0.2rem;
    transform: rotate(135deg);
  }

  &:after {
    left: 0.46rem;
    transform: rotate(45deg);
  }

  &.open {
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(135deg);
    }
  }
}
