.custom-button {
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  background-color: var(--primary-85);
  color: var(--white);
  font-family: var(--font-text);
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: border-color 0.2s ease, background-color 0.2s ease,
    color 0.2s ease;
  justify-content: center;
  font-weight: var(--font-bold);
  border-radius: var(--border-radius);

  &:hover {
    background-color: var(--primary-100);
  }

  &:disabled {
    background-color: var(--primary-10);
    color: var(--primary-40);
    cursor: default;
  }

  &.google-sign-in {
    background-color: #fff;
    color: var(--black-60);
    border: 1px solid var(--black-20);

    &:hover {
      border-color: var(--black-60);
    }
  }

  &.inverted {
    background-color: transparent;
    color: inherit;
    border: thin solid;
    border-color: var(--primary-20);

    &:hover {
      border-color: var(--primary-85);
    }
  }

  &.white {
    background-color: var(--white);
    color: var(--primary-85);

    &:hover {
      background-color: var(--primary-07);
    }
  }

  &.transparent {
    background-color: transparent;
    color: var(--primary-85);
    padding: 0.75rem;

    &:disabled {
      color: var(--primary-40);
    }
  }

  &.submit {
    background: none;
    color: var(--primary-85);
    padding: 10px;

    &:disabled {
      color: var(--primary-40);
    }
  }

  &.text-button {
    background: none;
    padding: 0;
    color: inherit;
    border-bottom: 1.5px solid var(--primary-70);
    color: var(--primary-80);

    &:hover {
      color: var(--primary-85);
      border-color: var(--primary-85);
    }
  }
}
