@import "../../sharedStyles/breakpoints.scss";

.footer {
  background-color: var(--primary-90);
  color: var(--primary-30);
  flex-shrink: 0;
  margin: 0;

  .footer-inner {
    display: flex;
    justify-content: space-between;
    padding: 4rem var(--page-margin-x);
    max-width: 1268px;
    margin: 0 auto;
  }

  .footer-ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 0.5rem;
  }

  li,
  .icon,
  li a {
    transition: color 0.2s ease;
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }

  a {
    color: var(--primary-30);
  }

  h4 {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-top: 0;
    margin-bottom: 1.125rem;
    transition: color 0.2s ease;
    color: var(--primary-10);
  }

  .icon-modernist {
    width: 154px;
    height: 24px;
    color: #fff;
    margin-bottom: 0.5rem;
    display: inline-block;
  }

  .footer-logo span {
    display: block;
  }

  .footer-links {
    width: 75%;
    display: flex;
    justify-content: space-between;
  }

  .social-media {
    display: flex;
    margin-top: 1rem;

    .icon {
      width: 32px;
      height: 32px;

      &:hover {
        color: #fff;
      }
    }

    a {
      &:not(:last-of-type) {
        margin-right: 0.5rem;
      }
    }

    .icon-link {
      max-height: 32px;
    }
  }

  [class^="col-"] {
    width: 150px;
  }

  .small {
    font-size: 0.75rem;
    line-height: 1.5;
  }

  .accordion-component {
    --hover-color: var(--white);
    --button-color: var(--primary-20);
    border: 0;
    margin-bottom: 0;

    .accordion-title-container {
      padding: 0 0 0.5rem;
    }

    .accordion-content {
      padding-bottom: 0;
      margin-bottom: 0;
      padding-top: 0.5rem;
    }
  }
}

// move logo column to bottom
@media (max-width: 950px) {
  .footer {
    .footer-inner {
      flex-wrap: wrap;
      padding-top: 3rem;
      padding-bottom: 3rem;

      .footer-links {
        width: 100%;
      }
    }

    .footer-logo {
      order: 2;
      width: 100%;
      padding-top: 1rem;
      border-top: 1px solid var(--primary-80);
      margin-top: 2rem;

      span {
        display: inline;

        &:last-of-type {
          margin-left: 0.25rem;
        }
      }
    }

    .icon-modernist {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
}

@media (max-width: $mobile-large) {
  .footer {
    .footer-links {
      display: block;
    }

    [class^="col-"] {
      width: 100%;
    }

    .footer-ul {
      padding-left: 1rem;
    }

    [class^="col-"]:not(.col-4) {
      margin-bottom: 1rem;
    }

    h4 {
      margin-bottom: 1rem;
    }

    .social-media {
      margin-top: 1.125rem;
    }

    .footer-logo {
      margin-top: 2.375rem;
    }

    .toggle-button {
      display: flex;
    }

    .accordion-component {
      .accordion-content {
        margin-bottom: 1rem;
      }
    }
  }
}

// move copyright line below logo
@media (max-width: 505px) {
  .footer .icon-modernist {
    display: block;
    margin-bottom: 0.5rem;
  }
}
