.mobile-nav-directory {
  background: #fff;
  height: calc(100vh - var(--nav-height));
  overflow-y: auto;
  width: 100%;
  position: absolute;
  left: 0;
  top: var(--nav-height);
  padding: 1rem 0;
  z-index: -1;

  /*-- search styles --*/
  .search-wrapper {
    margin-bottom: 0.5rem;
  }

  .search-dropdown {
    position: static;
    box-shadow: none;
    border-bottom: 1px solid var(--primary-10);
    border-radius: 0;
  }

  /*-- search result --*/
  .search-result:not(:last-of-type) {
    border-bottom: 1px solid (var(--primary-10));
  }

  .result-link {
    padding: 0.75rem 0;

    &:hover,
    &:focus {
      background-color: #fff;
    }
  }

  .result-details {
    width: calc(100% - 65px);
    display: flex;
    justify-content: space-between;
  }

  .result-name {
    flex-basis: calc(100% - (40px + 1rem));
  }
  .result-price {
    font-size: 1rem;
    margin-left: 1rem;
  }

  .view-results {
    border-top: 0;
    background-color: var(--primary-85);
    color: #fff;
    border-radius: var(--border-radius);
    pointer-events: unset;
    font-weight: var(--font-bold);
    margin-bottom: 0.75rem;
    font-size: 0.9rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: var(--primary-100);
    }
  }

  .no-results {
    padding: 1rem 0;
  }
}

@media (min-width: 801px) {
  .mobile-nav-directory {
    display: none;
  }
}
