.cart-container {
  height: 100%;

  .cart-icon {
    cursor: pointer;
  }

  .icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  .icon-shopping-bag {
    width: 24px;
    height: 24px;
    pointer-events: none;
  }

  .item-count {
    position: absolute;
    font-size: 10px;
    bottom: 1px;
    pointer-events: none;
    font-family: var(--font-text-cond);
  }
}
