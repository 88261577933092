.cart-item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 0.75rem;
  border-bottom: 1px solid var(--primary-15);

  &:last-of-type {
    border-bottom: none;
  }

  .cart-item-img {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
    background-color: var(--primary-07);
    border-radius: 2px;
    display: block;
    padding: 0.5rem;
  }

  .item-content-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 1rem;
    gap: 0.25rem 1rem;
    margin-left: 0.75rem;
  }

  .item-name {
    line-height: 1.25;
  }

  .item-name,
  .item-price {
    font-size: 0.824rem;
  }

  .item-price {
    text-align: right;
  }

  .item-detail-container {
    color: var(--primary-65);
    font-size: 0.75rem;
  }

  .item-detail {
    margin: 0;
    line-height: 1.125;

    &:not(:first-of-type) {
      margin-top: 0.1875rem;
    }
  }

  .option-category {
    text-transform: capitalize;
  }

  .remove-button {
    align-self: end;
    font-size: 0.675rem;
  }

  .delete-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.125rem 0.25rem 0.25rem;

    &:focus,
    &:hover {
      [class*="icon-"] {
        stroke: var(--primary-85);
      }
    }
  }
}

@media (max-width: 430px) {
  .cart-item {
    padding: 0.75rem 1.5rem;
  }
}
