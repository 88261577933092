// Show up to 3 full cart items. Additional items appear with scrollbar.
$cart-item-height: calc(5rem + 0.75rem + 0.75rem);
$cart-items-height: calc(#{$cart-item-height} * 3 + 2px);

.cart-dropdown {
  position: absolute;
  width: 360px;
  display: flex;
  flex-direction: column;
  background-color: white;
  top: var(--nav-height);
  right: 0;
  z-index: 5;
  border-top: 1px solid var(--primary-15);
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
  font-size: 0.85rem;
  line-height: 1.3;
  border-radius: 0 0 calc(var(--border-radius) * 2)
    calc(var(--border-radius) * 2);

  .empty-message {
    margin: 1rem auto;
  }

  .cart-items {
    max-height: $cart-items-height;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .cart-total {
    font-weight: var(--font-bold);
    text-align: center;
    padding: 0.75rem;
    border-bottom: 1px solid var(--primary-15);
    border-top: 1px solid var(--primary-15);
  }

  .custom-button {
    width: 100%;
  }

  .btn-container {
    padding: 0.75rem;
  }
}

@media (max-width: 430px) {
  .cart-dropdown {
    width: 100%;
    left: 0;
    right: 0;
    position: fixed;

    .btn-container {
      padding: 0.75rem 1.5rem;
    }
  }
}
