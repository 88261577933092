.search-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  background: var(--primary-07);
  border-radius: 3px;
  position: relative;

  .icon-search {
    position: absolute;
    margin-left: 1rem;
    width: 19px;
    height: 19px;
  }

  .form-wrapper {
    flex: 1;
  }

  .search-form {
    flex: 1;
  }

  .search-input {
    border: none;
    font-family: var(--font-text);
    background-color: transparent;
    font-size: 1rem;
    padding: 0.5rem 3rem;
    position: relative;
    width: 100%;
  }

  ::placeholder {
    color: var(--primary-40);
  }

  .clear-btn {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    color: var(--primary-85);
    position: absolute;
    right: 0;
    height: 100%;
    cursor: pointer;
    transition: color 0.2s;

    &:disabled {
      color: var(--primary-30);
      cursor: default;
    }
  }

  .icon-close {
    width: 15px;
    height: 15px;
  }
}
