.search-dropdown {
  box-shadow: 0 4px 8px var(--shadow-color);
  border-radius: 4px;
  position: absolute;
  width: 100%;
  background: white;

  .search-results {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li:not(.search-result) {
    padding: 0.75rem;
    transition: background-color 0.3s ease;
  }

  .view-results {
    text-align: center;
    border-top: 1px solid var(--primary-05);
    border-radius: 0 0 4px 4px;
    padding: 0.75rem;
    display: block;
    font-weight: var(--font-bold);
    font-size: 0.9rem;

    &:hover {
      background-color: var(--primary-05);
    }
  }

  .no-results {
    padding: 0.75rem;
  }
}
