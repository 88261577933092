#app {
  display: flex;
  flex-direction: column;

  &:after {
    content: "";
    position: fixed;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    width: 100%;
    height: 100vh;
    background: var(--overlay);
    top: 0;
    z-index: 1;
  }

  &.dropdown-visible:after,
  &.cart-visible:after {
    visibility: visible;
    opacity: 1;
    top: var(--nav-height);
    height: calc(100vh - var(--nav-height));
  }
}

.font-bold {
  font-weight: var(--font-bold);
}

.font-semibold {
  font-weight: var(--font-semibold);
}

.font-normal {
  font-weight: var(--font-normal);
}

.text-center {
  text-align: center;
}

.grey-text {
  color: var(--primary-70);
}

.red-text {
  color: var(--red);
}

.overflow-hidden {
  overflow: hidden;
}

.content-window {
  flex: 1 0 auto;
  margin-top: var(--nav-height);
}

.subtitle {
  font-size: 0.75rem;
  display: block;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.page-width {
  max-width: var(--max-page-width);
  width: 100%;
  padding-left: var(--page-margin-x);
  padding-right: var(--page-margin-x);
  margin-left: auto;
  margin-right: auto;
}

.object-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.text-link {
  border-bottom: 1px solid var(--primary-50);
  display: inline-block;
  line-height: 1.25;
  transition: color 0.2s ease, border-color 0.2s ease;
  color: var(--primary-70);
  cursor: pointer;

  &:hover {
    color: var(--primary-85);
    border-color: var(--primary-85);
  }
}

.line-clamp-2 {
  /* Limit the number of lines to 2 */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  /* Add ellipsis at the end of the second line */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  left: -9999px;
}

@media (max-width: 800px) {
  /* hide shop dropdown and input dropdown elements on mobile */
  #app {
    &.shop-dropdown-visible,
    &.input-visible {
      overflow: unset;
      height: 100%;
    }

    &.dropdown-visible:after {
      visibility: hidden;
      opacity: 0;
    }
  }
}
