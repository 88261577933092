@import "../../sharedStyles/breakpoints";

.accordion-component {
  --button-color: var(--primary-40);
  --hover-color: var(--primary-85);
  --transition-duration: 0.2s;
  --h5-margin: 1.5rem 0 0.5rem;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: var(--primary-20);

  h5 {
    font-size: 0.85rem;
    letter-spacing: 1.5px;
    font-weight: var(--font-semibold);
    margin: var(--h5-margin);
    text-transform: uppercase;

    + p {
      margin-top: 0;
    }
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    margin: 0;
  }

  li {
    margin: 0;
    padding-left: 1.25em;
    text-indent: -1.25em;

    + li {
      margin-top: 0.25rem;
    }
  }

  .accordion-content {
    margin-bottom: 1.5rem;
    line-height: 1.75;
  }

  &.collapsed {
    .accordion-content {
      margin-bottom: 0;
    }

    .toggle-button {
      &:after {
        transform: rotate(90deg);
      }

      &:before {
        transform: rotate(180deg);
      }
    }
  }

  + .accordion-component {
    border-top: 0;
  }

  .accordion-title-container {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 1rem 0;

    &:hover {
      .accordion-title {
        color: var(--hover-color);
      }

      .toggle-button {
        &:before,
        &:after {
          background-color: var(--hover-color);
        }
      }
    }
  }

  .accordion-title {
    transition: color var(--transition-duration) ease;
    margin: 0;
  }

  .toggle-button {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    position: relative;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before,
    &:after {
      background-color: var(--button-color);
      content: "";
      height: 0.125rem;
      position: absolute;
      width: 0.75rem;
      transition: transform var(--transition-duration) ease-in,
        background-color 0.2s ease;
    }

    &:after {
      transform-origin: center;
    }
  }
}

/* 
 * Mobile-only
 */

.accordion-component.mobile-only {
  .accordion-content {
    padding-top: 0;
    overflow: hidden;
    height: auto;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    max-height: 2000px;
  }

  &.collapsed {
    .accordion-content {
      max-height: 0;
      transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    }
  }
}

@media (min-width: $non-mobile) {
  .accordion-component.mobile-only {
    .toggle-button {
      display: none;
    }

    .accordion-title-container {
      pointer-events: none;
    }

    .accordion-content {
      max-height: unset;
    }

    &.collapsed {
      .accordion-content {
        max-height: unset;
      }
    }
  }
}
