@import "../../sharedStyles/breakpoints";
@import "../../sharedStyles/functions.scss";

.shop-dropdown-wrapper {
  --grid-gap: 2rem;
  width: 100%;
  background: var(--white);
  top: 100%;
  left: 0;
  position: absolute;
  border-width: 1px 0px;
  border-color: var(--primary-10);
  border-style: solid;
  font-size: 0.9rem;
  padding: 2rem 0;

  .shop-dropdown-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--grid-gap);
    max-width: 90.3rem;
  }

  .shop-dropdown-category-container {
    min-width: 12rem;
  }

  .shop-dropdown-header {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }

  .shop-dropdown-list-item {
    &:hover {
      color: var(--primary-85);
      cursor: pointer;

      .shop-link-text:after {
        background: var(--primary-85);
      }
    }
    &:not(:first-of-type) {
      margin-top: scaleValue(6px, 10px, 1000px, 1340px);
    }
  }

  .shop-link {
    display: block;
  }

  .shop-link-text,
  .shop-dropdown-img-caption {
    position: relative;
  }

  .shop-link-text:after,
  .shop-dropdown-img-caption:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: transparent;
    left: 0;
    bottom: 1px;
  }

  .img-container {
    aspect-ratio: 1/1;
    background-color: var(--primary-07);
    line-height: 0;
    margin-bottom: 0.5rem;
  }

  .shop-dropdown-img {
    flex: 1;
    width: 100%;
    object-fit: cover;

    &:hover {
      opacity: 0.8;
    }
  }

  .shop-dropdown-img-caption {
    font-size: 0.84rem;
    line-height: 1;

    &:hover {
      color: var(--primary-85);
      &:after {
        background-color: var(--primary-85);
      }
    }
  }
}

@media (max-width: $tablet) {
  .shop-dropdown-wrapper {
    --grid-gap: 1.5rem;
  }
}
