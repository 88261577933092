.mbl-nav-category {
  .mbl-nav-link {
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--primary-10);
    justify-content: space-between;
    font-weight: var(--font-bold);
    color: var(--primary-80);
  }

  .link-content {
    display: flex;
    align-items: center;
  }

  .icon-container {
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  }

  [class^="icon-"]:not(.icon-arrow-right) {
    color: var(--primary-50);
    transition: color 0.2s;
  }

  .icon-sofas,
  .icon-chairs {
    width: 40px;
    height: 40px;
  }

  .icon-bedding {
    width: 38px;
    height: 38px;
  }

  .icon-tables,
  .icon-signin {
    width: 30px;
    height: 30px;
  }

  .icon-favorites {
    width: 28px;
    height: 28px;
  }

  .icon-signout {
    width: 26px;
    height: 26px;
  }

  .icon-lighting,
  .icon-decor {
    width: 35px;
    height: 35px;
  }

  .icon-arrow-right {
    width: 14px;
    height: 14px;
    color: var(--primary-20);
    pointer-events: none;
  }
}
